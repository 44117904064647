/* Mixins */
.linkage-config-drawer .ant-select {
  min-width: 150px;
  display: inline-block;
}
.linkage-config-drawer .operate-value {
  width: 150px;
  display: inline-block;
}
.linkage-config-drawer .linkage-config-item-wrapper {
  margin-bottom: 8px;
}
.linkage-config-drawer .linkage-config-item-wrapper .linkage-config-item-content {
  background-color: #f0f2f5;
  padding: 16px;
}
.linkage-config-drawer .linkage-config-item-wrapper .linkage-config-item-content .ant-select {
  margin-right: 16px;
}